.explore-recoil {
    @include font-reset();
    position: absolute;
    width: 100%;
    &>div {
        position: relative;
        top: 100vh;
        margin-top: -5em;
        height: 5em;
        width: 100%;
        z-index: 100;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-eurostile-lt-exp;
        .explore {
            margin-top: 2em;
            font-size: .7em;
            font-weight: 900;
            letter-spacing: .05em;
            color: $orange-bg;
            -webkit-transition: color $hov-speed;
            transition: color $hov-speed;
        }
        a:hover .explore {
            color: white;
        }
        .down {
            position: absolute;
            padding: 0 auto 0 auto;
            left: 0;
            right: 0;
        }
        .down svg {
            padding-top: .5em;
            width: .7em;
            opacity: 1;
            -webkit-transition: width $hov-speed, margin-top $hov-speed, opacity $hov-speed, fill $hov-speed;
            transition: width $hov-speed, margin-top $hov-speed, opacity $hov-speed, fill $hov-speed;
        }
        a .down2 svg {
            -webkit-transition: fill $hov-speed;
            transition: fill $hov-speed;
        }
        a:hover .down svg {
            margin-top: -.25em;
            width: 1em;
            fill: white;
        }
        a:hover .down2 svg {
            margin-top: -3em;
            width: 5em;
            opacity: 0;
            -webkit-transition: width $hov-speed, margin-top $hov-speed, opacity $hov-speed, fill $hov-speed;
            transition: width $hov-speed, margin-top $hov-speed, opacity $hov-speed, fill $hov-speed;
        }
    }
}