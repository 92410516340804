/* news.scss */

body.news {
    background-color: black;
    background-image: url(/assets/images/bg-news.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
}

section.news {
    display: block;
    position: relative;
    height: auto;
    width: 100vw;
    background-color: transparent;
    padding: 0 0 0vw 0;
    a {
        text-decoration: none;
    }
    .inner {
        display: block;
        position: relative;
        width: 90vw;
        padding: 0 5vw 0 5vw;
    }
    .headline {
        display: block;
        position: relative;
        font-family: $font-eurostile-lt-exp;
        color: $text-grey;
        font-size: 2.5em;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 0em;
        text-transform: uppercase;
        text-align: center;
        padding: 10vh 0 12vh 0;
        color: rgb(198, 203, 204);
        background: -webkit-linear-gradient(top, rgb(255, 255, 255), rgb(154, 163, 165));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .news-side {
        display: block;
        position: relative;
        float: left;
        width: 20vw;
        margin: 0;
        padding: 0 2vw 0 0;
        min-height: 50vh;
        border-right-color: rgba(64, 64, 64, 0.2);
        border-right-style: solid;
        border-right-width: 0.1vw;
        box-sizing: border-box;
        .title {
            font-family: $font-eurostile-lt-exp;
            color: $orange-bg;
            font-size: 1.5em;
            line-height: 1em;
            font-weight: 300;
            letter-spacing: 0em;
            text-transform: uppercase;
            padding: 0 0 0.8em 0;
            margin-bottom: 0.8em;
            border-bottom-color: rgba(64, 64, 64, 0.2);
            border-bottom-style: solid;
            border-bottom-width: 0.1vw;
        }
        .widget {
            display: block;
            position: relative;
            font-family: $font-eurostile-lt-demi;
            color: $text-grey;
            font-size: 0.9em;
            line-height: 1em;
            font-weight: 300;
            letter-spacing: 0em;
            padding: 0.5em 0 0.5em 0;
            &:after {
                content: '';
                display: block;
                clear: both;
            }
            img {
                display: inline-block;
                position: relative;
                float: left;
                max-width: 2.5em;
                margin: 0;
                padding: 0em 1em 0em 0;
            }
            a {
                display: inline-block;
                position: relative;
                color: $text-grey;
                text-decoration: none;
                padding: 0.7em 0em 0.7em 0;
            }
        }
        @media only screen and (max-width: $max-size-tablet) {
            float: none;
            width: 90vw;
            padding: 0 0vw 10vw 0vw;
            min-height: 0vh;
            border-right-width: 0;
        }
    }
    .news-main {
        display: block;
        position: relative;
        float: left;
        width: calc(72vw - 8vw);
        padding: 0 0vw 0 4vw;
        margin: 0;
        article.post {
            display: block;
            border-bottom-color: rgba(64, 64, 64, 0.2);
            border-bottom-style: solid;
            border-bottom-width: 0.1vw;
            padding: 2vw 0 2vw 0;
            &:last-child {
                border-bottom-width: 0;
                margin-bottom: 5vw;
            }
            &:after {
                content: '';
                display: block;
                clear: both;
            }
            .featured {
                display: block;
                position: relative;
                width: 30%;
                height: auto;
                float: left;
                div {
                    display: block;
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    img {
                        height: auto;
                        width: 100%;
                        position: relative;
                    }
                }
            }
            .content {
                display: block;
                position: relative;
                width: calc(70% - 2vw);
                height: auto;
                float: left;
                padding: 0.5vw 0 0 2vw;
                .title {
                    font-family: $font-eurostile-lt-exp;
                    color: $text-grey;
                    font-size: 1.3em;
                    line-height: 1.3em;
                    font-weight: 500;
                    letter-spacing: 0em;
                    text-align: left;
                    text-transform: uppercase;
                    padding-top: 0em;
                    padding-bottom: 0.5em;
                    white-space: normal;
                }
                .link {
                    font-family: $font-eurostile-lt;
                    color: $text-grey-darkest;
                    font-size: 1em;
                    line-height: 1em;
                    font-weight: 500;
                    letter-spacing: 0em;
                    text-align: left;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }
        }
        @media only screen and (max-width: $max-size-tablet) {
            float: none;
            width: 86vw;
            padding: 0 2vw 0 2vw;
        }
    }
    .pagination {
        display: block;
        position: relative;
        clear: both;
        width: 100%;
        height: 4.5vw;
        padding: 1vw 0vw 1vw 0vw;
        text-align: right;
        z-index: 100;
        vertical-align: middle;
        a.next,
        a.prev {
            display: inline-block;
            position: relative;
            //float: right;
            vertical-align: middle;
            font-family: $font-eurostile;
            color: #aaacaf;
            font-size: 1em;
            line-height: 1em;
            font-weight: 500;
            letter-spacing: 0.2em;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            margin: 1vw;
            padding: 0;
            width: auto;
            span {
                display: block;
                height: 2vw;
                line-height: 2vw;
                vertical-align: middle;
            }
        }
        a.page-link,
        a.current {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            //float: right;
            font-family: $font-eurostile;
            color: $text-grey;
            font-size: 1.1em;
            line-height: 1em;
            font-weight: 300;
            letter-spacing: 0em;
            text-align: left;
            width: 1.5em;
            height: 1.5em;
            text-transform: uppercase;
            text-decoration: none;
            margin: 1em;
            color: $orange-bg;
            padding: 0;
            border: 0.15vw solid $orange-bg;
            box-sizing: border-box;
            vertical-align: middle;
            span {
                //background-color: #000;
                font-size: 0.8em;
                line-height: 1em;
                vertical-align: middle;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            @media only screen and (max-width: $max-size-tablet) {
                width: 1em;
                height: 1em;
                margin: 0.5em;
            }
        }
        a.current {
            color: #aaacaf;
            border: 0.15vw solid #aaacaf;
        }
        @media only screen and (max-width: $max-size-tablet) {
            text-align: center;
            width: 90vw;
            padding: 5vw 0vw 5vw 0vw;
        }
    }
}

section.as-seen-module {
    display: block;
    position: relative;
    width: 90vw;
    height: auto;
    padding: 1vw 5vw 5vw 5vw;
    background-color: rgba(0, 0, 0, 0);
    z-index: 6;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 6.5vw;
        width: 100vw;
        top: -6.2vw;
        z-index: -1;
        background-image: url(/assets/images/news-footer-bg.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top center;
        @media only screen and (max-width: $max-size-tablet) {
            display: none;
        }
    }
    .title {
        font-family: $font-eurostile-lt-exp;
        color: #969798;
        font-size: 1.5em;
        line-height: 1em;
        font-weight: 500;
        letter-spacing: 0em;
        padding: 0 0 0.25em 0;
        text-transform: uppercase;
    }
    .as-seen {
        display: inline-block;
        position: relative;
        float: left;
        width: auto;
        height: auto;
        padding: 0vw 2vw 1vw 0vw;
        img {
            display: block;
            width: auto;
            height: auto;
            max-height: 9vw;
        }
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    @media only screen and (max-width: $max-size-tablet) {
        padding: 5vw 5vw 5vw 5vw;
    }
}

section.awards-module {
    display: block;
    position: relative;
    width: 90vw;
    height: auto;
    padding: 1vw 5vw 5vw 5vw;
    background-color: rgba(0, 0, 0, 0);
    .title {
        font-family: $font-eurostile-lt-exp;
        color: #969798;
        font-size: 1.5em;
        line-height: 1em;
        font-weight: 500;
        letter-spacing: 0em;
        padding: 0 0 1em 0;
        text-transform: uppercase;
    }
    .award {
        display: inline-block;
        position: relative;
        float: left;
        width: auto;
        height: auto;
        padding: 1vw 2vw 1vw 0vw;
        img {
            display: block;
            width: auto;
            height: auto;
            max-height: 9vw;
        }
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}