.nav {
    position: fixed;
    height: calc(4.4em * 5 + .15em * 5);
    top: 0px;
    bottom: 0px;
    margin: auto 0 auto 0;
    z-index: 160;
    .navitem {
        position: relative;
        height: auto;
        width: 3em;
        margin-top: 0em;
        margin-bottom: 3px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 1.3em;
        vertical-align: middle;
        .nav-inner {
            display: block;
            position: relative;
            width: 3em;
            height: 2.5em;
            overflow: hidden;
            box-shadow: -1px -1px 0 $dark-bg, -1px 1px 0 $dark-bg;
        }
        svg {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 1.3em;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .nav-inner,
    .navitem::before,
    .navitem::after {
        width: 3em;
        background-color: $dark-bg;
        -webkit-transition: background-image $hov-speed, padding-left $hov-speed, background-color $hov-speed, box-shadow $hov-speed;
        transition: background-image $hov-speed, padding-left $hov-speed, background-color $hov-speed, box-shadow $hov-speed;
        box-shadow: 0px 0px 0px $dark-bg;
        -webkit-font-smoothing: antialiased;
        -webkit-backface-visibility: hidden;
    }
    .navitem:hover .nav-inner,
    .navitem:hover::before,
    .navitem:hover::after,
    .navitem.selected .nav-inner,
    .navitem.selected::before,
    .navitem.selected::after {
        background-color: $orange-bg;
        padding-left: .5em;
        box-shadow: 0px 0px .5em $orange-bg, -1px -1px 0 $orange-bg, -1px 1px 0 $orange-bg;
    }
    .navitem:hover svg,
    .navitem.selected svg {
        fill: $dark-bg;
    }
    /*
	.nav-home .navitem{
		//background-image: url("/assets/images/nav-home.png");
	}
	.nav-home:hover .navitem{
		//background-image: url("/assets/images/nav-home-hover.png");
	}
	
	.nav-strikers-guide .navitem{
		svg{
			width: 1.3em;
		}
		//background-image: url("/assets/images/nav-strikers-guide.png");
	}
	.nav-strikers-guide:hover .navitem{
		//background-image: url("/assets/images/nav-strikers-guide-hover.png");
	}
	
	.nav-how-to .navitem{
	//	background-image: url("/assets/images/nav-how-to.png");
	}
	.nav-how-to:hover .navitem{
		//background-image: url("/assets/images/nav-how-to-hover.png");
	}
	
	.nav-weapons .navitem{
		//background-image: url("/assets/images/nav-weapons.png");
	}
	.nav-weapons:hover .navitem{
		//background-image: url("/assets/images/nav-weapons-hover.png");
	}*/
    .nav-products .navitem {
        svg {
            width: 1.3em;
        }
        //background-image: url("/assets/images/nav-products.png");
    }
    .nav-products:hover .navitem {
        //background-image: url("/assets/images/nav-products-hover.png");
    }
}

.mob-nav {
    @include font-reset();
    @include only-mobile();
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 14vw;
    height: 12.5vw;
    background-color: $orange-bg;
    z-index: 1000;
    -webkit-transition: width $hov-speed, height $hov-speed, background-color $hov-speed;
    transition: width $hov-speed, height $hov-speed, background-color $hov-speed;
    div.container {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        display: none;
    }
    div.hamburger {
        cursor: pointer;
        transition: opacity $hov-speed;
        width: 9vw;
        height: 9vw;
        opacity: 1;
        z-index: 1002;
        svg {
            position: absolute;
            fill: $dark-bg;
            top: 3vw;
            left: 3vw;
            width: 8vw;
            height: 8vw;
        }
    }
    a.anchor {}
    div.navitem {
        color: $orange-bg;
        text-transform: uppercase;
        font-size: 4.5vw;
        line-height: 10vw;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, rgba(35, 35, 35, 0) 0%, rgba(35, 35, 35, 1) 50%, rgba(35, 35, 35, 0) 100%);
        border-image-slice: 1;
        div.icon {
            display: inline-block;
            padding: 5vw 4vw;
            height: 10vw;
        }
        svg {
            width: 8vw;
        }
    }
    .page-nav {
        position: relative;
        text-align: center;
        display: block;
        padding: 1em 0vw 1em 0vw;
        span {
            display: inline-block;
            font-family: $font-eurostile-lt;
            color: $text-grey;
            text-transform: uppercase;
            font-size: 0.8em;
            font-weight: 300;
            line-height: 1em;
        }
        a {
            display: inline-block;
            font-family: $font-eurostile-lt;
            color: $text-grey;
            text-transform: uppercase;
            font-size: 3.5vw;
            font-weight: 300;
            line-height: 1em;
            padding: 2em 5vw 2em 5vw;
            &.news {
                display: inline-block;
            }
            &.support {
                display: inline-block;
            }
        }
    }
    .army {
        color: #464646;
        font-size: 3vw;
        text-align: center;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        width: 100%;
        img {
            padding: 5vw;
            height: 9vw;
            opacity: .4;
        }
    }
}

.mob-nav.expanded {
    width: 100%;
    height: 100%;
    background-color: #151515;
    div.hamburger {
        opacity: 0;
    }
}


/* This is for the 45 degree corner */

.mob-nav:after {
    position: fixed;
    content: "";
    position: absolute;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: bottom right;
    bottom: -1.5vw;
    right: 1.5vw;
    height: 1.5vw;
    -webkit-transform: skewX(-45deg);
    -ms-transform: skewX(-45deg);
    transform: skewX(-45deg);
    width: 100%;
    background-color: $orange-bg;
    box-shadow: -1px -1px 0 $orange-bg;
}

@media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
    .nav {
        display: none;
    }
    .mob-nav,
    .mob-nav:after {
        display: block;
    }
}