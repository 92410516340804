section.home {
    background-image: url(/assets/images/bg-home.jpg);
    .grenade {
        text-transform: uppercase;
        position: absolute;
        display: none;
        right: 0;
        bottom: 0;
        width: 20em;
        height: 25em;
        @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
            bottom: 35vw;
        }
        .grenade-bg {
            height: 70%;
            width: 70%;
            position: absolute;
            bottom: 2em;
            right: 4em;
            background-image: url(/assets/images/home-grenade.png);
            background-size: contain;
            background-position: bottom right;
            background-repeat: no-repeat;
            -webkit-filter: drop-shadow(-1em 1em 2em rgba(0, 0, 0, .5));
            filter: drop-shadow(-1em 1em 2em rgba(0, 0, 0, .5));
            @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
                z-index: 10;
                height: 50%;
                width: 50%;
                right: -5vw;
                top: 26%;
            }
        }
        .grenade-text {
            position: absolute;
            left: -11em;
            text-transform: uppercase;
            text-align: right;
            bottom: 4.5em;
            width: 15em;
            @media only screen and (max-width: $max-size-tablet) {
                display: none;
            }
            .text-1 {
                color: #b5c1c3;
                font-size: .7em;
            }
            .text-2 {
                font-weight: 900;
                font-size: .8em;
            }
            .text-3 {
                color: #5a5e5f;
                font-size: .9em;
                margin-top: .5em;
                font-family: $font-eurostile;
            }
        }
        .grenade-overlay {
            position: absolute;
            padding-right: 1em;
            height: 5em;
            width: 9em;
            background-color: $orange-bg;
            right: 0;
            bottom: 9.8em;
            text-align: right;
            font-family: $font-eurostile-lt-demi;
            color: #0e0d0e;
            font-weight: normal;
            opacity: .8;
            -webkit-transition: padding-right $hov-speed, box-shadow $hov-speed;
            transition: padding-right $hov-speed, box-shadow $hov-speed;
            @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
                padding-right: 20vw;
                height: 5.2em;
            }
            .free {
                color: #FFF;
                padding-top: .2em;
                font-size: 1.2em;
            }
            .text {
                white-space: nowrap;
                padding-top: .5em;
                font-weight: bold;
                font-size: .7em;
                line-height: 1.2em;
            }
        }
        .grenade-overlay::before,
        .grenade-overlay::after {
            background-color: $orange-bg;
            width: 100%;
        }
        a:hover .grenade-overlay::before,
        a:hover .grenade-overlay::after,
        a:hover .grenade-overlay {
            @include box-shadow();
            padding-right: 1.5em;
            @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
                padding-right: 22vw;
            }
        }
    }
    .rotating-text {
        position: absolute;
        text-align: right;
        right: 4em;
        bottom: 5em;
        width: 17em;
        @media only screen and (max-width: $max-size-tablet) {
            bottom: 20vw;
        }
        @media only screen and (max-width: $max-size-mobile) {
            bottom: 50vw;
        }
        &::after {
            content: "";
            position: absolute;
            display: block;
            background-image: url(/assets/images/darkener.png);
            background-size: contain;
            width: 30em;
            height: 30em;
            top: -20em;
            left: -5.5em;
            z-index: 0;
        }
        &>div {
            position: absolute;
            display: none;
            right: 0;
            bottom: 0;
            width: 17em;
            text-align: right;
            z-index: 1;
            p {
                font-size: 1.6em;
                background: -webkit-linear-gradient(145deg, #aaa, #555);
                background: -o-linear-gradient(145deg, #aaa, #555);
                background: -moz-linear-gradient(145deg, #aaa, #555);
                background: linear-gradient(145deg, #aaa, #555);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: $font-eurostile-lt-demi;
                font-style: italic;
                padding-right: .2em; //because italic gets cut off otherwise
                font-weight: 500;
                line-height: 1.2em;
            }
            /*				p::before {
									background: -webkit-linear-gradient(145deg, #aaa , #6f7172);
					background: -o-linear-gradient(145deg, #aaa , #6f7172);
					background: -moz-linear-gradient(145deg, #aaa , #6f7172);
					background: linear-gradient(145deg, #aaa , #6f7172); 
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;

					content: attr(data-text);
					right: 0;
					position: absolute;
					top: 0;
					z-index:2;
					padding-right:.2em;
				}
				
/*				p::after {
					background: none;
					content: attr(data-text);
					right: 0;
					position: absolute;
					text-shadow: 0px 0px 1em rgba(0, 0, 0, 1),0px 0px 1em rgba(0, 0, 0, 1);
					top: 0;
					z-index:1;
					padding-right:.2em;
				}*/
            span {
                padding-top: 1em;
                font-size: .7em;
            }
        }
    }
    .home-overlay {
        font-family: $font-eurostile-lt-exp;
        //		background-image: url(/assets/images/home-fg-base.png);
        background-image: url(/assets/images/bg-home-overlay.png);
        background-size: 100%;
        @media only screen and (max-width: $max-size-tablet) {
            background-size: 150%;
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            //background-size: 250%;
            font-size: 2.6vw;
            background-image: url(/assets/images/bg-home-overlay-mobile.png);
            height: 47vw;
            background-size: 100%;
        }
        background-position:center bottom;
        background-repeat:no-repeat;
        position:absolute;
        bottom:0;
        width: 100%;
        height: 13.5em;
        text-align:center;
        color: $orange-bg;
        text-transform: uppercase;
        .theworld {
            margin-top: .3em;
            font-weight: 900;
            letter-spacing: .2em;
        }
        img.logo {
            padding-top: 3.3em;
            width: 27em;
        }
        a.button {
            @media only screen and (min-width: $min-size-tablet) {
                display: none;
            }
            width:90%;
            font-size:5vw;
            margin:5%;
        }
    }
    .overlay {
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    @media only screen and (max-width: $max-size-mobile) {
        a.play-button div.play-button {
            top: 40%;
        }
    }
}