//Top Desktop Navigation
.upper-nav-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4.2em;
    z-index: 5;
    background-image: url(/assets/images/home-top-bar.png);
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    @media only screen and (max-width: 1000px) and (min-width: $min-size-tablet) {
        background-size: 150%;
        height: 8.4em;
    }
    @media only screen and (max-width: $max-size-tablet) and (min-width: $min-size-tablet) {
        background-size: 220%;
        height: 10.4em;
    }
    @media only screen and (max-width: $max-size-mobile) {
        display: none;
    }
    .experience {
        position: relative;
        text-align: left;
        display: none;
        z-index: 6;
        margin: 0.2em 10vw 0.2em 5vw;
        a {
            display: inline-block;
            font-family: $font-eurostile-lt-cond;
            color: $text-grey;
            text-transform: uppercase;
            font-size: 0.8em;
            text-align: left;
            line-height: 1em;
            &:hover {
                color: $orange-bg;
            }
        }
    }
    .nav-right {
        position: relative;
        text-align: left;
        display: inline-block;
        float: right;
        z-index: 6;
        margin: 0.2em 5vw 0.2em 10vw;
        span {
            display: inline-block;
            font-family: $font-eurostile-lt;
            color: $text-grey;
            text-transform: uppercase;
            font-size: 0.8em;
            font-weight: 300;
            line-height: 1em;
        }
        a {
            display: inline-block;
            font-family: $font-eurostile-lt;
            color: $text-grey;
            text-transform: uppercase;
            font-size: 0.8em;
            font-weight: 300;
            line-height: 1em;
            &.news {
                display: inline-block;
            }
            &.support {
                display: inline-block;
            }
            &:hover {
                color: $orange-bg;
            }
            &.selected {
                color: $orange-bg;
            }
        }
    }
    .countdown {
        position: absolute;
        top: .3em;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 16.9em;
        height: 3.2em;
        //background-color: #141412;
        color: #777777;
        a {
            width: calc(100% - 3em);
            left: 0;
            right: 0;
            margin: .5em auto 0 auto;
        }
        @media only screen and (max-width: 1000px) and (min-width: $min-size-tablet) {
            font-size: 1.5em;
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            height: 12.5vw;
        }
    }
    .countdown {
        text-transform: uppercase;
        font-family: $font-eurostile-lt-exp;
        .begins {
            font-size: .6em;
            text-align: center;
            margin: .5em 0;
        }
        .cnt-time {
            height: 1em;
            font-family: clock;
            font-size: 3em;
            text-align: center;
            color: $orange-bg;
            position: absolute;
            top: .3em;
            left: 0;
            width: 100%;
            @include text-shadow();
        }
        .cnt-time::before {
            content: "00:00:00:00";
            position: absolute;
            top: 0vw;
            left: 0vw;
            width: 100%;
            opacity: .1;
        }
        .cnt-sub {
            position: absolute;
            top: 7.3em;
            font-size: .5em;
            width: 100%;
            &>div {
                display: inline-block;
                width: 16.7%;
                margin-left: 6%;
            }
        }
        a.button {
            position: absolute;
            bottom: 0em;
            @include no-mobile();
        }
        a.button:before,
        a.button:after {
            z-index: 10000;
        }
    }
    .countdown::before,
    .countdown::after {
        //background-color: #141412;
    }
}

.devices {
    .experience {
        display: inline-block;
    }
}

.news {
    .experience {
        display: inline-block;
    }
}