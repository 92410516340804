section.community {
    background-image: url(/assets/images/bg-social-tagboard.jpg);
    font-family: $font-eurostile-lt-exp;
    height: auto;
    min-height: 100vh;
    .header-wrap {
        display: block;
        margin-left: 5vw;
        margin-top: 0vw;
        text-align: left;
        &>h1 {
            margin-top: 10vh;
            font-family: $font-eurostile-lt-exp;
            text-transform: uppercase;
            font-size: 3.2vw;
            font-weight: 900;
            letter-spacing: 0.5vw;
            color: rgb(198, 203, 204);
            background: -webkit-linear-gradient(top, rgb(255, 255, 255), rgb(154, 163, 165));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &>h2 {
            font-family: $font-eurostile-lt-exp;
            text-transform: uppercase;
            font-size: 0.8vw;
            font-weight: 600;
            padding-top: 0vh;
            color: rgb(171, 171, 171);
            line-height: 3em;
        }
    }
    .tagboard-wrap {
        display: block;
        width: 90vw;
        padding: 0 5vw 0 5vw;
    }
}