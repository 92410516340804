section.devices {
    background-color: #000;
    background-image: url(/assets/images/bg-devices.jpg);
    background-size: 100% auto;
    background-size: cover;
    background-repeat: repeat;
    background-position: top center;
    height: auto;
    width: 100vw;
    overflow: visible;
    padding: 5vh 0 5vh 0;
    .headline {
        display: block;
        position: relative;
        font-family: $font-eurostile-lt-exp;
        color: $text-grey;
        font-size: 2.5em;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        padding: 5vh 0 3vh 0;
        text-align: center;
        color: rgb(198, 203, 204);
        background: -webkit-linear-gradient(top, rgb(255, 255, 255), rgb(154, 163, 165));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .content-full {
        display: block;
        width: 64vw;
        padding: 0vw 18vw 3vw 18vw;
        font-family: $font-eurostile-lt;
        display: inline-block;
        text-align: center;
        font-size: 1.2em;
        line-height: 1.8em;
        font-weight: 500;
        color: $text-grey;
        white-space: normal;
    }
    .supported {
        display: block;
        width: 76vw;
        padding: 0vw 12vw 5vw 12vw;
        .title {
            display: block;
            font-family: $font-eurostile-lt-exp;
            color: #b1b1b1;
            font-size: 2em;
            line-height: 1em;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            text-align: center;
            padding: 5vh 0 1vh 0;
            text-align: center;
        }
        .subtitle {
            display: block;
            font-family: $font-eurostile-lt;
            font-size: 1.1em;
            line-height: 1em;
            font-weight: 500;
            color: $text-grey-darker;
            text-transform: normal;
            padding: 0 0 3vw 0;
            text-align: center;
            min-height: 1.5em;
            @media only screen and (max-width: $max-size-mobile) {
                padding: 0 0 10vw 0;
            }
        }
        .device {
            display: block;
            font-family: $font-eurostile-lt;
            font-size: 1.6em;
            line-height: 1em;
            font-weight: 500;
            letter-spacing: 0.1em;
            color: $text-grey-dark;
            text-transform: normal;
            text-align: center;
            padding: 0 0 1em 0;
        }
        .notes {
            font-family: $font-eurostile-lt;
            display: inline-block;
            font-size: 1em;
            line-height: 1.3em;
            font-weight: 500;
            padding: 0 0 1em 0;
            color: $text-grey-darker;
            white-space: normal;
            padding-top: 2em;
            &:last-child {
                padding-top: 0em;
            }
        }
        .col1 {
            display: block;
            width: 48%;
            padding-right: 2%;
            float: left;
            @media only screen and (max-width: $max-size-mobile) {
                width: 90%;
                padding-right: 0%;
                padding-bottom: 2em;
            }
        }
        .col2 {
            display: block;
            width: 48%;
            padding-right: 2%;
            float: left;
            @media only screen and (max-width: $max-size-mobile) {
                width: 90%;
                padding-right: 0%;
                padding-bottom: 2em;
            }
        }
        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }
}