* {
    -webkit-transition: color $hov-speed, color $hov-speed, text-shadow $hov-speed, box-shadow $hov-speed;
    transition: color $hov-speed, color $hov-speed, text-shadow $hov-speed, box-shadow $hov-speed;
}

body {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    background-color: #000;
    font-family: $font-eurostile-lt-exp;
    @include font-reset();
}

body section {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    display: block;
}

svg {
    fill: $orange-bg;
}

.st0 {
    fill: inherit;
    stroke: inherit;
}

svg {
    -webkit-transition: fill $hov-speed, filter $hov-speed;
    transition: fill $hov-speed, filter $hov-speed;
}

a.button {
    position: absolute;
    display: block;
    margin-top: .5em;
    text-align: center;
    div {
        position: relative;
        z-index: 101;
        text-transform: uppercase;
        display: block;
        font-family: $font-eurostile-lt-demi;
        color: #000;
        font-weight: bold;
        padding: 5px;
        line-height: 1.3em;
        height: 1.3em;
        background-size: auto 5em;
        background-position: center center;
        font-size: .8em;
        letter-spacing: .1em;
        //background-color: $orange-bg;//color set in svg
        background-image: url(/assets/images/cta-texture.png);
        //clip-path: polygon(.7em 0, calc(100% - .7em) 0, 100% .7em, 100% calc(100% - .7em), calc(100% - .7em) 100%, .7em 100%, 0 calc(100% - .7em), 0 .7em);
    }
    svg.clip-svg {
        position: absolute;
        display: block;
        overflow: hidden;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        .bg {
            clip-path: url(#clip-bevelbox);
        }
    }
}

a.button>div,
a.button::before,
a.button::after,
a.button>div::before,
a.button>div::after {}

a.button::before {
    //background-size: auto 2.5em;
    //background-position: center bottom;
}

a.button::after {
    //background-size: auto 2.5em;
    //background-position: center bottom;
}

a.button>div::before {
    //background-size: auto 2.5em;
    //background-position: center top;
}

a.button>div::after {
    //background-size: auto 2.5em;
    //background-position: center top;
}

a.button:hover>div,
a.button:hover::before,
a.button:hover::after,
a.button:hover>div::before,
a.button:hover>div::after {
    box-shadow: 0px 0px 0em $orange-bg;
}

h1 {
    font-family: $font-eurostile-lt-exp;
    text-transform: uppercase;
    @include font-reset(3.2);
    font-weight: 900;
    letter-spacing: .5vw;
    color: #c6cbcc;
    background: -webkit-linear-gradient(#fff, #9aa3a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 25vh;
    svg {
        height: 2.5vw;
        fill: #fcfcfc;
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        margin-top: 10vh;
    }
}

h2 {
    font-family: $font-eurostile-lt-exp;
    text-transform: uppercase;
    @include font-reset(.7);
    font-weight: bold;
    padding-top: 2.5vh;
    color: #ababab;
    line-height: 3em;
    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
        line-height: 2em;
    }
}

h3 {
    font-family: $font-eurostile-lt-exp;
    text-transform: uppercase;
    color: #ee4a1c;
    background: -webkit-linear-gradient(#f94d1c, #e7481b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include font-reset(2);
    line-height: 1.5em;
}

.no-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.no-scroll-nav {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.no-mobile {
    @include no-mobile();
}

.only-mobile {
    @include only-mobile();
}

a.play-button {
    @include font-reset();
    div.play-button {
        z-index: 100;
        position: absolute;
        height: 0;
        width: 10em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        &>div {
            display: inline-block;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }
        svg {
            fill: #fff;
            transition-delay: fill 0s;
        }
        svg.triangle {
            font-size: 1em;
            width: 1.8em;
            transition: transform 2s, width .5s, fill .3s;
        }
        svg.inner {
            font-size: 1em;
            width: 6em;
            transition: transform .3s, width .3s, fill .3s;
        }
        svg.dashes {
            font-size: 1em;
            width: 7.5em;
            transition: transform .3s, width .3s, fill .3s;
        }
        svg.outer {
            font-size: 1em;
            width: 9em;
            transition: transform .3s, width .3s, fill .3s;
        }
    }
}

a.play-button:hover {
    div.play-button {
        overflow: visible;
        svg {
            overflow: visible !important;
            fill: $orange-bg;
        }
        svg.inner {
            -ms-transform: rotate(360deg);
            /* IE 9 */
            -webkit-transform: rotate(360deg);
            /* Chrome, Safari, Opera */
            transform: rotate(360deg);
            font-size: 1.3em;
            transition: transform 2s, width .4s, fill .4s;
        }
        svg.dashes {
            -ms-transform: rotate(-360deg);
            /* IE 9 */
            -webkit-transform: rotate(-360deg);
            /* Chrome, Safari, Opera */
            transform: rotate(-360deg);
            font-size: 1.3em;
            transition: transform 2s, width .7s, fill .7s;
        }
        svg.outer {
            -ms-transform: rotate(90deg);
            /* IE 9 */
            -webkit-transform: rotate(90deg);
            /* Chrome, Safari, Opera */
            transform: rotate(90deg);
            font-size: 1.3em;
            transition: transform 2s, width 1s, fill 1s;
        }
    }
}

div.close {
    @include font-reset();
    width: 2.5vw;
    position: absolute;
    top: 2.5vw;
    right: 2.5vw;
    cursor: pointer;
    z-index: 75;
    svg {
        fill: $orange-bg;
    }
}

div.close:hover {
    svg {
        fill: #FFF;
    }
}

@media only screen and (min-width: $min-size-tablet) {
    div.close svg.close {
        display: none;
    }
}

@media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
    div.close svg.overlay-close {
        display: none;
    }
    div.close {
        cursor: pointer;
        position: absolute;
        top: 0vw;
        right: 0vw;
        width: 14vw;
        height: 12.5vw;
        z-index: 1001;
        border-left: 1px solid #353535;
        svg {
            fill: $orange-bg;
            width: 10vw;
            height: 10vw;
            margin: 1.5vw 0 0 2vw;
        }
    }
    div.close:after {
        position: fixed;
        content: "";
        position: absolute;
        -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        transform-origin: bottom right;
        bottom: -1.5vw;
        left: 1.5vw;
        height: 1.5vw;
        -webkit-transform: skewX(45deg);
        -ms-transform: skewX(45deg);
        transform: skewX(45deg);
        width: 100%;
        border-left: 1px solid #353535;
        border-bottom: 1px solid #353535;
    }
}

.ambient-video {
    height: auto !important;
    width: auto !important;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video {
    background-color: #000;
    background-image: url("/assets/images/svgs/loading.svg");
    background-size: 10% auto;
    background-position: center;
    background-repeat: no-repeat;
}

// blocks
.st1 {
    fill: rgba(50, 50, 50, .4);
    stroke-width: 1px;
    stroke-miterlimit: 10;
}

// product blocks
.st2 {
    fill: rgba(50, 50, 50, .4);
    stroke-width: 1px;
    stroke-miterlimit: 10;
}

.rotate-mobile {
    display: none;
}

@media only screen and (max-width: $max-size-tablet) and (orientation:landscape) {
    section,
    div.nav,
    div.mob-nav {
        display: none;
    }
    .rotate-mobile {
        display: block;
        width: 100%;
        height: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 50vh;
            margin: 10vh auto;
        }
        h3 {
            text-align: center;
            margin-top: 60vh;
        }
    }
}