.footer {
    @include font-reset();
    height: 13.5em;
    background-color: #000;
    overflow: hidden;
    color: #5f6063;
    text-transform: uppercase;
    position: relative;
    float: left;
    width: 100%;
    .fg {
        box-sizing: border-box;
        position: absolute;
        height: 13.5em;
        padding: 5.2em 3.3em 0 3.3em;
        width: 100%;
        z-index: 10;
        &>.left {
            float: left;
            width: 33em;
            font-size: .9em;
            font-family: $font-eurostile;
            img {
                width: 20em;
                margin-bottom: .5em;
            }
        }
        &>.right {
            float: right;
            width: 33em;
            font-size: 1em;
            text-align: right;
            img {
                height: 2.8em;
                margin: .5em 0 0 1em;
            }
        }
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        overflow: hidden;
        svg {
            height: 25em;
            margin: -2.5em auto 0 auto;
            display: block;
            right: 0;
            left: 0;
        }
        .st0 {
            opacity: 0.4;
        }
        .st1 {
            fill: #58595B;
        }
        .st2 {
            opacity: 0.4;
            fill: #58595B;
        }
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        height: 60vw;
        text-align: center;
        padding: 0;
        div.social {
            margin-top: 3vw;
        }
        .left {
            margin-top: 12vw;
        }
        .fg {
            padding: 12vw 0 0 0;
            &>.left {
                width: 100%;
            }
            &>.right {
                width: 100%;
                text-align: center;
                img {
                    margin: 0 6vw;
                    height: 4em;
                }
            }
        }
    }
}

.social a img {
    -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.80));
    -webkit-transition: all $hov-speed;
    -o-transition: all $hov-speed;
    transition: all $hov-speed;
}

.social a:hover img {
    -webkit-filter: drop-shadow(0px 0px .5em rgba(250, 76, 6, 0.8));
}