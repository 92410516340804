section.products {
    background-image: url(/assets/images/bg-products.jpg);
    font-family: $font-eurostile-lt-exp;
    height: auto;
    .armory {
        //background-image: url(/assets/images/products-armory.png);
        text-align: center;
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        height: 15vw;
        padding-top: 5vw;
        @media only screen and (max-width: $max-size-tablet) and (min-width: $min-size-tablet) {
            padding-top: 2vw;
        }
        h1 {
            padding-top: 0vw;
            margin-top: 0;
        }
        &>h1,
        &>h2 {
            position: absolute;
            width: 100%;
        }
        &>h2 {
            margin-top: 3vw;
        }
        .grenade_block {
            margin-left: 38vw;
            margin-top: 17.5vw;
            text-align: left;
            color: #666;
            position: absolute;
            display: none;
            width: 100%;
            @media only screen and (max-width: $max-size-tablet) and (min-width: $min-size-tablet) {
                margin-top: 18.5vw;
            }
            .limited {
                font-size: .6em;
                color: #fff;
                text-transform: uppercase;
            }
            h1 {
                font-size: 1em;
                padding-top: .2em;
                letter-spacing: 0;
            }
            .text {
                font-size: .7em;
                line-height: 1.2em;
                font-family: $font-eurostile-lt;
                font-weight: 300;
            }
        }
    }
    .trusted {
        font-family: $font-eurostile-lt;
        font-weight: 300;
        letter-spacing: .1vw;
        text-transform: uppercase;
        font-size: .7vw;
        clear: both;
        height: 1vw;
    }
    .order-buttons {
        margin-top: 3vw;
        color: #4a4a4a;
        a {
            margin-top: 1.5vw;
        }
        @media only screen and (max-width: $max-size-tablet) and (min-width: $min-size-tablet) {
            a div {
                font-size: 1vw;
            }
        }
    }
    .retailers {
        a img {
            height: 2.5vw;
            opacity: 1;
            transition: opacity $hov-speed, filter $hov-speed;
        }
        a:hover img {
            opacity: .8;
            -webkit-filter: drop-shadow(0px 0px .5em $orange-bg);
            filter: drop-shadow(0px 0px .5em $orange-bg);
        }
    }
    .product-blocks {
        position: relative;
        margin: 0 8.5vw 4.5vw 8.5vw;
        float: left;
        width: 83%;
        @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
            margin: 0 2.5vw 8.5vw 2.5vw;
        }
        .product-block {
            position: relative;
            margin-bottom: 4vw;
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: visible;
                svg {
                    stroke: $orange-bg;
                    width: 100%;
                }
            }
            .fg {
                background-image: url(/assets/images/svgs/product-scanlines.svg);
                background-size: 40vw auto;
                background-position: left top;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
        .product-block {
            height: 54.2vw;
            float: left;
            width: 100%;
        }
        @media only screen and (min-width: $min-size-tablet) {
            .product-block {
                width: 39vw;
            }
            .product-block.tall.right {
                float: right;
            }
            .product-block.tall {
                .bg.wide {
                    display: none;
                }
            }
            .product-block.wide {
                width: 100%;
                clear: both;
                height: 34vw;
                .bg.tall {
                    display: none;
                }
            }
            .product-block.wide {
                .title {
                    margin-left: 14vw;
                }
            }
        }
        .product-block {
            font-size: 1vw;
            @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
                font-size: 2.3vw;
            }
            .for-use {
                position: absolute;
                margin-top: 6em;
                margin-left: -1em;
                padding: .5em;
                width: 7.5em;
                line-height: 1.2em;
                //font-size: .8em;
                text-transform: uppercase;
                //background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(180, 180, 180, 1) 100%);
                /* FF3.6-15 */
                //background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(180, 180, 180, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                //background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(180, 180, 180, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                text-align: right;
                font-family: $font-eurostile;
                background-color: #f26c23;
                border-radius: 0 .5em .5em 0;
                text {
                    font-size: 1.6em;
                    color: #FFF;
                    font-family: $font-eurostile-bold;
                }
                b {
                    font-family: $font-eurostile-bold;
                    font-size: 1.4em;
                    font-weight: 900;
                }
                -webkit-box-shadow: 0 .25em .5em rgba(0, 0, 0, .25);
                -moz-box-shadow: 0 .25em .5em rgba(0, 0, 0, .25);
                box-shadow: 0 .25em .5em rgba(0, 0, 0, .25);
            }
            .for-use:after {
                position: absolute;
                left: 0;
                bottom: -1em;
                content: "";
                width: 0;
                height: 0;
                border-top: 1em solid #994416;
                border-left: 1em solid transparent;
            }
            .free-grenade {
                position: absolute;
                display: none;
                overflow: hidden;
                height: 20em;
                width: 20em;
                top: 0;
                right: 0;
                .banner {
                    box-sizing: border-box;
                    position: absolute;
                    top: 7.7em;
                    right: -15em;
                    padding: 2em 0 0 7em;
                    background-color: $orange-bg;
                    height: 5.5em;
                    width: 30em;
                    transform: rotate(45deg);
                    transform-origin: 50% -140%;
                    text-transform: uppercase;
                    font-size: 1em;
                    font-family: $font-eurostile-bold;
                    .limited {
                        color: #FFF;
                        font-size: .6em;
                    }
                    .preorder {
                        font-size: .9em;
                    }
                    .free {
                        font-size: 1.8em;
                    }
                }
            }
            .fg>.left {
                width: 40em;
                float: left;
            }
            .fg>.right {
                width: 35em;
                float: left;
                color: #8e8e8e;
                text-transform: uppercase;
                .includes {
                    color: #4a4a4a;
                }
                .details {
                    margin: 2em 0;
                    .left {
                        vertical-align: middle;
                        border-right: 1px solid $orange-bg;
                        width: 50%;
                        display: inline-block;
                        img {
                            height: 4.5em;
                            padding-right: .0vw;
                            line-height: 7em;
                            vertical-align: middle;
                        }
                        img:first-child {
                            padding-right: 1em;
                        }
                        img.hub {
                            height: 6em;
                        }
                        img:only-child {
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .right {
                        margin-left: 1em;
                        font-size: .7em;
                        line-height: 1.5em;
                        width: 45%;
                        display: inline-block;
                        @media only screen and (max-width: $max-size-tablet) {
                            font-size: .9em;
                        }
                    }
                }
            }
            h1 {
                margin: 2.8em 0 0 0;
                font-size: 1.6em;
                letter-spacing: 0;
            }
            h3 {
                font-size: 2.4em;
                margin: 0;
                line-height: 1.1em;
            }
            h2 {
                font-size: .9em;
                padding-top: 0;
                text-transform: none;
            }
            img.packaging {
                height: 19em;
                margin-left: 8em;
                margin-top: .5em;
            }
            .subtext {
                margin-top: 1em;
                margin-left: 14em;
                font-size: .8em;
                color: #5b5554;
                line-height: 1em;
                sup {
                    font-size: .8em;
                }
                img {
                    height: 1em;
                    margin-bottom: -.1em;
                }
                a {
                    font-size: .8em;
                    line-height: 1em;
                    color: #697e81;
                    margin-left: 1em;
                    text-decoration: underline;
                }
            }
        }
        @media only screen and (min-width: $min-size-tablet) {
            .product-block.wide .fg>.right {
                padding-top: 12em;
                padding-left: 3em;
            }
            .product-block.tall .fg {
                .title {
                    text-align: center;
                }
                &>.left {
                    .packaging {
                        margin: .5vw auto 0 auto;
                        text-align: center;
                        display: block;
                    }
                }
                &>.right {
                    margin-top: 2em;
                    margin-left: 3em;
                    .details>.left img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            .product-block {
                height: 130vw;
                margin-top: 10vw;
                .bg.wide {
                    display: none;
                }
                .fg {
                    .title {
                        text-align: center;
                    }
                    &>.left {
                        .packaging {
                            margin: .5vw auto 0 auto;
                            text-align: center;
                            display: block;
                        }
                    }
                    &>.right {
                        clear: both;
                        margin-top: 2em;
                        margin-left: 3em;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        .product-blocks {
            width: 95%;
        }
        .armory {
            background-image: none;
            background-position: top;
            background-size: 100% auto;
            background-repeat: no-repeat;
            height: 50vw;
            &>h1 {
                text-align: center;
                font-size: 8vw;
                margin-top: 10vw;
            }
            &>h2 {
                width: 80%;
                text-align: center;
                margin-left: 10%;
                margin-top: 24vw;
            }
            .grenade_block {
                margin-top: 70vw;
                margin-left: 30%;
                width: 70%;
                h1 {
                    margin-top: 0;
                }
                h3 {
                    line-height: 1em;
                }
                .text {
                    margin-top: 2vw;
                    font-size: 3vw;
                    line-height: 1.5em;
                }
            }
            .order-buttons {
                margin-top: 20vw;
                margin-left: -25vw;
                width: 85vw;
            }
        }
        .order-buttons {
            width: 95%;
            margin-top: 3vw;
            a.button {
                width: 100%;
                position: relative;
                div {
                    text-align: center;
                    font-size: 4vw;
                }
            }
            .trusted {
                margin-top: 4vw;
                position: relative;
                font-size: 3vw;
                left: 0;
                height: auto;
                margin-left: 0;
            }
            .retailers {
                position: relative;
                left: 0;
                margin-top: 1vw;
                margin-left: 0;
                a {
                    img {
                        height: 9vw;
                        padding-right: 2vw;
                    }
                }
            }
        }
    }
}