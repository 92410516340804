#error_404 {
    background-color: #141412;
}

section.error_404 {
    background-image: url(/assets/images/bg-home.jpg);
    &>.countdown {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 16.9em;
        height: 6.8em;
        background-color: #141412;
        color: #777777;
        a {
            width: calc(100% - 3em);
            left: 0;
            right: 0;
            margin: .5em auto 0 auto;
        }
        @media only screen and (max-width: 1000px) and (min-width: $min-size-tablet) {
            font-size: 10px;
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            height: 12.5vw;
        }
    }
    .countdown {
        text-transform: uppercase;
        font-family: $font-eurostile-lt-exp;
        .begins {
            font-size: .7em;
            text-align: center;
            margin: .5em 0;
        }
        .cnt-time {
            height: 1em;
            font-family: clock;
            font-size: 3em;
            text-align: center;
            color: $orange-bg;
            position: absolute;
            top: .3em;
            left: 0;
            width: 100%;
            @include text-shadow();
        }
        .cnt-time::before {
            content: "00:00:00:00";
            position: absolute;
            top: 0vw;
            left: 0vw;
            width: 100%;
            opacity: .1;
        }
        .cnt-sub {
            position: absolute;
            top: 7.3em;
            font-size: .5em;
            width: 100%;
            &>div {
                display: inline-block;
                width: 16.7%;
                margin-left: 6%;
            }
        }
        a.button {
            position: absolute;
            bottom: 0em;
            @include no-mobile();
        }
        a.button:before,
        a.button:after {
            z-index: 10000;
        }
    }
    .countdown::before,
    .countdown::after {
        background-color: #141412;
    }
    .content-404 {
        width: 50vw;
        height: auto;
        margin: 0;
        z-index: 105;
        position: absolute;
        display: block;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
            width: 70vw;
        }
        .image-404 {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
            width: 80%;
        }
        .content {
            text-transform: uppercase;
            color: #FFFFFF;
            font-size: 1.5em;
            line-height: 1.3em;
            font-weight: 800;
        }
        a.button {
            position: relative;
            display: inline-block;
            margin-top: 3em;
            margin-left: auto;
            margin-right: auto;
            div {
                padding: 1em 2.5em 1em 2.5em;
                font-size: 0.9em;
                line-height: 1.3em;
                height: 1.3em;
                background-size: auto 5em;
                background-position: center 40%;
            }
        }
    }
}