$nav-height: 9vw;
.overlay {
    &>.bg {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-size: cover;
        -webkit-transition: background .5s;
        transition: background .5s;
    }
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 2000;
    display:none;
    -webkit-transition: transform .5s;
    transition: transform .5s;
    //transform: scale(.9,.9);
    .overlay-nav {
        height: $nav-height;
        position: absolute;
        top: 0;
        left: 0;
        width: 68vw;
        padding-left: 8.5vw;
        font-size: 1vw;
        z-index: 50;
        @include no-mobile();
        h1 {
            font-size: .9em;
            margin-top: 1vw;
            opacity: .5;
            letter-spacing: default;
        }
        div.item {
            display: inline-block;
            width: 7vw;
            text-align: center;
            cursor: pointer;
            font-size: .6em;
            color: #8b8b8b;
            vertical-align: top;
            text-transform: uppercase;
            z-index: 50;
            .icon {
                height: 4.5vw;
                vertical-align: middle;
                margin-bottom: 1vw;
                svg {
                    fill: #8b8b8b;
                    overflow: visible !important;
                }
                svg.report {
                    height: 2vw;
                    margin-top: 2.5vw;
                }
                svg.hub {
                    height: 4vw;
                    margin-top: 1vw;
                }
                svg.spitfire {
                    height: 3vw;
                    margin-top: 2vw;
                }
                svg.sound {
                    height: 3.5vw;
                    margin-top: 1.5vw;
                }
                svg.app {
                    height: 4vw;
                    margin-top: 1vw;
                }
                svg.rogue {
                    height: 3.5vw;
                    margin-top: 1.5vw;
                }
                svg.grenade {
                    height: 3.5vw;
                    margin-top: 1.5vw;
                }
            }
        }
        div.item.app {
            width: 10vw;
        }
        div.item {
            font-weight: 900;
        }
        div.item:not(.selected):hover {
            color: $orange-bg;
            @include text-shadow();
            .icon svg {
                fill: $orange-bg;
                -webkit-filter: drop-shadow( 0 0 .5vw $orange-bg) drop-shadow( 0 0 .5vw $orange-bg);
                filter: drop-shadow( 0 0 .5vw $orange-bg) drop-shadow( 0 0 .5vw $orange-bg);
            }
        }
        div.item.selected {
            color: $orange-bg;
            .icon svg {
                fill: $orange-bg;
            }
        }
    }
    .overlay-nav::after {
        content: "";
        height: $nav-height;
        position: absolute;
        top: 0;
        left: 0;
        width: 68vw;
        border-right: .2vw solid $orange-bg;
        border-bottom: .1vw solid $orange-bg;
        transform-origin: top right;
        -webkit-transform: skewX(-60deg);
        -ms-transform: skewX(-60deg);
        transform: skewX(-60deg);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.15) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.15) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.15) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00999999', endColorstr='#1affffff', GradientType=0);
        /* IE6-9 */
        z-index: -1;
    }
    .content {
        position: absolute;
        top: 0;
        left: 0;
        height: 102vh;
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            width: 0px;
            /* remove scrollbar space */
            background: transparent;
            /* optional: just make scrollbar invisible */
        }
        .content-bucket {
            position: absolute;
            height: 100vh;
        }
        .content-block {
            padding-top: $nav-height;
            width: 100vw;
            height: calc(100vh - 9vw);
            float: left;
            position: relative;
            div.arrow {
                width: 1.5vw;
                position: absolute;
                top: 40%;
                cursor: pointer;
                @include no-mobile();
            }
            .arrow {
                z-index: 75;
            }
            a.arrow svg {
                fill: #8b8b8b;
                overflow: visible !important;
            }
            a.arrow:hover svg {
                fill: $orange-bg;
                -webkit-filter: drop-shadow( 0 0 .5vw $orange-bg) drop-shadow( 0 0 .5vw $orange-bg);
                filter: drop-shadow( 0 0 .5vw $orange-bg) drop-shadow( 0 0 .5vw $orange-bg);
            }
            div.arrow-l {
                left: 4vw;
            }
            div.arrow-r {
                left: 93vw;
            }
            div.arrow-helper {
                position: absolute;
                font-family: $font-eurostile-lt-exp;
                text-transform: uppercase;
                font-size: 0.8vw;
                font-weight: 900;
                white-space: nowrap;
                color: #fa4c06;
                line-height: 1.2em;
                right: calc(100% + 3em);
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
    }
    .overlay-mobile-nav {
        @include only-mobile();
        position: absolute;
        bottom: 0px;
        margin: 0 auto;
        text-align: center;
        z-index: 75;
        left: 0;
        right: 0;
        .indicator {
            display: inline-block;
            margin: 0 5vw 5vw 5vw;
            width: 3vw;
            height: 3vw;
            border: 1px solid #aaa;
            border-radius: 1.5vw;
        }
        .spot {
            position: absolute;
            margin: calc(5.5vw + 1px);
            margin-top: calc(.5vw + 1px);
            width: 2vw;
            height: 2vw;
            border-radius: 1vw;
            background-color: $orange-bg;
        }
    }
    a.button {
        div {
            padding: 1em 2.5em 1em 2.5em;
            line-height: 1.5em;
            height: 1.5em;
            background-size: auto 10em;
            background-position: center 70%;
        }
    }
}

.overlay.visible {
    transform: scale(1, 1);
}