$nav-height: 9vw;
.how-to .overlay {
    &>.bg {
        background-image: url('/assets/images/bg-how-to-overlay.jpg');
    }
    .content {
        .content-bucket {
            width: 500vw;
        }
        .content-block {
            h1 {
                margin-top: .5vh;
                text-align: center;
                @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
                    font-size: 5vw;
                }
            }
            h1.pre {
                font-size: 1.3em;
                margin-top: 3vh;
            }
            h2 {
                text-align: center;
                padding: 1vh 10vw 0 10vw;
            }
            div.how-to-video {
                background-color: #000;
                width: calc( ( 69vh - 14vw) * 16 / 9);
                height: calc(69vh - 14vw);
                margin-top: 2vh;
                margin-left: 20vw;
                position: relative;
                @media only screen and (orientation: portrait) {
                    width: 90vw;
                    height: calc(90vw * 9/16);
                    margin-left: 5vw;
                }
                div.image {
                    position: absolute;
                    top: -10vh;
                    right: -50vh;
                    width: calc((69vh - 14vw)*1.7);
                    z-index: 10;
                    text-align: center;
                    img {
                        display: block;
                        width: 100%;
                    }
                    a {
                        display: none;
                        width: 78%;
                        margin-top: -7vh;
                        font-size: .7em;
                        line-height: 1em;
                        color: #697e81;
                        margin-left: 1em;
                        text-decoration: underline;
                    }
                    @media only screen and (orientation:portrait) {
                        width: 50vw;
                        top: auto;
                        bottom: -15vw;
                        right: -20vw;
                    }
                }
                iframe {
                    width: 100%;
                    height: 100%;
                }
                @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
                    margin-left: 5vw;
                    width: 90vw;
                    height: calc( 90vw * 9 / 16);
                    div.image {
                        right: -20vw;
                        width: 70vw;
                        top: auto;
                        bottom: -40vw;
                    }
                }
            }
            div.sub {
                margin-top: 2.5vh;
                margin-left: 10vw;
                color: $blue-bg;
                line-height: normal;
                img {
                    height: 15vh;
                }
                .text {
                    display: inline-block;
                    font-size: .6em;
                    font-weight: 900;
                    padding-left: 1vw;
                }
                .text1 {
                    h4 {
                        font-size: 1.2em;
                        text-transform: uppercase;
                    }
                }
                .text2 {}
                img.peeps {
                    margin-left: 4vh;
                    height: 7.5vh;
                }
            }
        }
        #strikers-guide-4 {
            div.how-to-video {
                div.image {
                    a {
                        display: block;
                    }
                }
            }
        }
        #strikers-guide-1 {
            .text1 {
                text-transform: uppercase;
                margin-left: -4vh;
            }
        }
        #strikers-guide-0 {}
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        div.sub {
            .image {
                padding-bottom: 2vw;
            }
            .text {
                line-height: 2em;
            }
        }
        .content-block#strikers-guide-1 {
            padding-top: 0;
            div.sub {
                position: absolute;
                img.image {
                    position: absolute;
                    top: 30vw;
                    left: -20vw;
                    height: 30vw;
                }
                .text1 {
                    position: absolute;
                    left: 55vw;
                    top: 35vw;
                    width: 40vw;
                    font-size: 2.5vw;
                }
                .text2 {
                    position: absolute;
                    left: 0vw;
                    top: 17vw;
                    font-size: 2.5vw;
                    width: 50vw;
                    br {
                        display: none;
                    }
                }
                .peeps {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: auto;
                    width: 35vw;
                    margin-left: 0;
                }
            }
        }
        .content-block#strikers-guide-3 {
            div.how-to-video div.image {
                bottom: -30vw;
                width: 100vw;
                right: -50vw;
            }
        }
        .content-block#strikers-guide-4 {
            div.how-to-video div.image {
                right: -30vw;
            }
        }
    }
    #strikers-guide-0 {
        h1 {
            text-align: left;
            margin-left: 10vw;
        }
        div.video {
            position: absolute;
            width: 60vw;
            height: calc( 70vw * 9 / 16);
            bottom: 10vw;
            left: 10vw;
            z-index: 76;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            div.video {
                width: 100vw;
                height: calc( 100vw * 9 / 16);
                left: 0;
                top: 30vh;
            }
        }
    }
}