section.how-to,
section.weapons {
    &>.bg {
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-image: url(/assets/images/bg-how-to.jpg);
    }
    .title {
        position: absolute;
        padding-left: 9vw;
        h1 {}
        h2 {
            width: 50vw;
        }
        @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
            h2 {
                width: 75vw;
                font-size: 2.6vw;
            }
        }
    }
    .boxes {
        position: absolute;
        padding: 50vh 9vw 0 9vw;
        font-family: $font-eurostile-lt-exp;
        text-transform: uppercase;
        @include font-reset(1, 1.2);
        color: $orange-bg;
        line-height: 1.2em;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        width: 100%;
        overflow: -moz-scrollbars-none!important;
        -ms-overflow-style: none;
        @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
            padding: 52vh 9vw 0 9vw;
        }
        &::-webkit-scrollbar {
            width: 0px;
            /* remove scrollbar space */
            background: transparent;
            /* optional: just make scrollbar invisible */
        }
        .box {
            position: relative;
            display: inline-block;
            width: 15em;
            margin-right: 2em;
            height: 15em;
            svg .st1 {
                stroke: $orange-bg;
            }
            div.fg {
                position: absolute;
                width: 15em;
                text-align: center;
                svg {
                    stroke: $orange-bg;
                    stroke-width: 0;
                    overflow: visible !important;
                }
                svg.hub {
                    height: 6.7em;
                    margin-top: 2em;
                }
                svg.spitfire {
                    height: 5.4em;
                    margin-top: 3.5em;
                }
                svg.sound {
                    height: 6.8em;
                    margin-top: 2.5em;
                }
                svg.app {
                    height: 7.8em;
                    margin-top: 2.5em;
                }
                svg.rogue {
                    height: 6em;
                    margin-top: 2.8em;
                }
                svg.grenade {
                    height: 6em;
                    margin-top: 2.8em;
                }
            }
            div.text {
                padding-top: .5em;
                padding-left: .5em;
                font-size: .65em;
            }
        }
        .box.rogue {
            width: 23em;
            div.fg {
                width: 23em;
            }
        }
        .box div.bg::before {
            content: "";
            top: 9.25em;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: 2.7em solid $orange-bg;
            border-left: 2.7em solid transparent;
        }
        .box div.bg::after {
            content: "+";
            top: 6.4em;
            right: 0;
            width: 0.833em;
            height: 1em;
            color: #000;
            font-size: 1.6em;
            font-weight: 100;
        }
        .box div.bg::before,
        .box div.bg::after {
            opacity: 0;
            position: absolute;
            -webkit-transition: opacity $hov-speed;
            transition: opacity $hov-speed;
        }
        a:hover .box {
            div.fg svg {
                -webkit-filter: drop-shadow( 0 0 2vw $orange-bg) drop-shadow( 0 0 2vw $orange-bg);
                filter: drop-shadow( 0 0 2vw $orange-bg) drop-shadow( 0 0 2vw $orange-bg);
            }
            div.bg::before,
            div.bg::after {
                opacity: 1;
            }
            div.text {
                @include text-shadow();
            }
        }
        &::-webkit-scrollbar {
            width: 0px;
            /* remove scrollbar space */
            background: transparent;
            /* optional: just make scrollbar invisible */
        }
    }
    .intro-thumb {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 19em;
        z-index: 150;
        span {
            display: block;
            font-size: .75em;
            line-height: 1em;
            text-align: center;
            color: #bbc5c7;
            text-transform: uppercase;
            padding-bottom: .4em;
        }
        .icon-plus {
            display: block;
            position: absolute;
            bottom: 0px;
            right: 0px;
            background-color: rgb(250, 76, 6);
            background-image: url(/assets/images/svgs/plus.svg);
            background-size: 80% 80%;
            background-position: center center;
            width: 3em;
            height: 3em;
            transition: all $hov-speed;
        }
        &:hover .icon-plus {
            width: 4em;
            height: 4em;
            //box-shadow: 0 0 0.5em #fa4c06;
        }
        img {
            width: 100%;
        }
        video {
            display: block;
            width: 100%;
            height: auto;
            &:hover {
                box-shadow: 0 0 0.5em #fa4c06;
            }
        }
    }
}