
/* this page is built heavily on how-to. */
section.weapons{
	&>.bg{
		background-image: url(/assets/images/bg-weapons.jpg);
	}
	h2{
	width:50vw;
	}

}