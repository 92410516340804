section.striker {
    background-image: url(/assets/images/bg-striker.jpg);
    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
        background-image: url(/assets/images/bg-striker-mobile.jpg);
    }
    font-family: $font-eurostile-lt-exp;
    text-align:center;
    text-transform:uppercase;
    .title {
        position: absolute;
        height: 0;
        width: 100%;
        @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
            h1 {
                font-size: 12vw;
                .col {
                    font-size: 6vw;
                    svg {
                        height: 4vw;
                    }
                }
            }
        }
    }
    .info-blocks {
        @include font-reset();
        position: relative;
        top: 70vh;
        height: 10em;
        font-weight: 900;
        .info-block {
            color: #6e8c92;
            text-align: left;
            margin: 0 2.2em;
            display: inline-block;
            height: 100%;
            width: 27em;
            background: -moz-linear-gradient(top, rgba(102, 127, 138, 0.2) 0%, rgba(99, 125, 133, 0.2) 50%, rgba(102, 127, 138, 0.2) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(102, 127, 138, 0.2) 0%, rgba(99, 125, 133, 0.2) 50%, rgba(102, 127, 138, 0.2) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(102, 127, 138, 0.2) 0%, rgba(99, 125, 133, 0.2) 50%, rgba(102, 127, 138, 0.2) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#66c2ccd0', endColorstr='#66c2ccd0', GradientType=0);
            /* IE6-9 */
            &>.left {
                margin-left: 5%;
                margin-top: 3.5%;
                height: 80%;
                width: 50%;
                float: left;
                border-right: 1px solid #6e8c92;
                .big-stat {
                    height: 50%;
                    float: left;
                }
                .w-l {
                    font-size: .7em;
                    margin-left: 8%;
                    margin-top: 8%;
                    display: inline-block;
                    span {
                        font-size: .8em;
                        i {
                            display: inline-block;
                            background-color: #6e8c92;
                            width: 0.8em;
                            height: 0.8em;
                            &.w {
                                background-color: #151919;
                            }
                        }
                    }
                }
                .lower {
                    width: 50%;
                    height: 50%;
                    font-size: .7em;
                    line-height: 1.2em;
                }
                .lower.left {
                    clear: both;
                    float: left;
                    .top {
                        height: 60%;
                        .blocks {
                            padding-top: 10%;
                            font-size: 1.4em;
                            transform: scale(.4, 1);
                            -webkit-transform: scale(.4, 1);
                            /* Safari and Chrome */
                            -moz-transform: scale(.4, 1);
                            /* Firefox */
                            -ms-transform: scale(.4, 1);
                            /* IE 9+ */
                            -o-transform: scale(.4, 1);
                            /* Opera */
                            transform-origin: top left;
                        }
                    }
                }
                .lower.right {
                    float: right;
                    .top {
                        height: 60%;
                        img {
                            height: 80%;
                        }
                    }
                }
            }
            &>.right {
                height: 80%;
                float: right;
                width: 38%;
                margin-top: 6%;
                font-size: 1em;
                line-height: 1.2em;
                .num {
                    font-size: 1.5em;
                    margin-bottom: 10%;
                }
                .standings {
                    font-size: .6em;
                    line-height: 1.4em;
                }
                .sub {
                    font-size: .4em;
                    line-height: 1.4em;
                }
            }
        }
        .info-block.right {
            @include no-mobile();
            &>.left {
                line-height: 2em;
                font-size: .7em;
                svg {
                    fill: #6e8c92;
                    height: 1.5em;
                    padding: 0;
                    margin: 0 -.2em;
                }
                .wings {
                    font-size: 1.6em;
                    margin-bottom: .8em;
                    svg {
                        height: .7em;
                    }
                }
                .stars {
                    margin-top: .5em;
                }
            }
            &>.right {
                padding-right: 8%;
                padding-top: 4%;
                width: 28%;
                text-align: center;
                svg {
                    height: 50%;
                    fill: #6e8c92;
                }
            }
        }
    }
    .overlay {
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}