/* footer.scss */

footer.footer-alt {
    position: relative;
    background-color: #1c1c1f;
    color: #FFF;
    text-align: center;
    padding: 2vw;
    .social-connect {
        display: inline-block;
        padding: 2em;
        color: $orange-bg;
        font-family: $font-eurostile;
        font-size: 0.9em;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        a {
            color: $orange-bg;
            text-decoration: none;
            transition: all 500ms ease-in-out 0s;
        }
        @media only screen and (max-width: $max-size-mobile) {
            font-size: 0.6em;
        }
    }
    .social {
        position: relative;
        width: auto;
        margin-bottom: 2em;
        a {}
        img {
            max-width: 3em;
            display: inline-block;
            margin-left: 2em;
            margin-right: 2em;
        }
    }
    .powered {
        display: block;
        text-align: center;
        position: relative;
        font-family: $font-eurostile-lt;
        color: #4b4d56;
        font-size: 0.7em;
        line-height: 1em;
        font-weight: 400;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        padding: 0.75em 0em 0.75em 0em;
    }
    .logo {
        display: block;
        text-align: center;
        position: relative;
        max-width: 12vw;
        margin-left: auto;
        margin-right: auto;
        padding: 0.5em 0em 0.5em 0em;
        @media only screen and (max-width: $max-size-mobile) {
            max-width: 35vw;
        }
    }
    .copy {
        font-family: $font-eurostile;
        padding: 1em 0em 5em 0em;
        color: #4b4d56;
        font-size: 0.8em;
        line-height: 1.5em;
        font-weight: 300;
    }
}