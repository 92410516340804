/* mixins.scss */

@mixin text-shadow() {
    text-shadow: 0px 0px .5vw $orange-bg;
    @media only screen and (max-width: $max-size-tablet) {
        text-shadow: 0px 0px 1vw $orange-bg;
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        text-shadow: 0px 0px 1.5vw $orange-bg;
    }
}

@mixin box-shadow() {
    box-shadow: 0px 0px .5vw $orange-bg;
    @media only screen and (max-width: $max-size-tablet) {
        box-shadow: 0px 0px 1vw $orange-bg;
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        box-shadow: 0px 0px 1.5vw $orange-bg;
    }
}

//FONTS
@mixin font-reset($size:1, $sizem:1.5, $sizel:3) {
    font-size: 1vw * $size;
    @media only screen and (max-width: $max-size-tablet) {
        font-size: 1vw * $sizem * $size;
    }
    @media only screen and (max-width: $max-size-mobile) and (orientation:portrait) {
        font-size: 1vw * $sizel * $size;
    }
}

//Device Specific
@mixin no-mobile() {
    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
        display: none;
    }
}

@mixin only-mobile() {
    @media only screen and (min-width: $min-size-tablet) {
        display: none;
    }
}

//General Styling
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin transform-origin($params) {
    -webkit-transform-origin: $params;
    -ms-transform-origin: $params;
    transform-origin: $params;
}

//@include transition(width 2s linear 1s);
@mixin transition($str) {
    -webkit-transition: #{$str};
    -moz-transition: #{$str};
    -ms-transition: #{$str};
    -o-transition: #{$str};
    transition: #{$str};
}

// translate
//@include translate(-50%, -50%);
@mixin translate($top, $left) {
    transform: translate($left, $top);
    -ms-transform: translate($left, $top);
    /* IE 9 */
    -webkit-transform: translate($left, $top);
    /* Safari and Chrome */
    -o-transform: translate($left, $top);
    /* Opera */
    -moz-transform: translate($left, $top);
    /* Firefox */
}

// =============================================================================
// Font Face
// =============================================================================
//@include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_"));
    $formats: ( otf: "opentype", ttf: "truetype");
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}