	.tab-right::before,
	.tab-right::after,
	.tab-left::before,
	.tab-left::after,
	.tab-down::before,
	.tab-down::after,
	.tab-up::before,
	.tab-up::after,
	a.button::before,
	a.button::after,
	a.button>div::before,
	a.button>div::after {
	    content: "";
	    position: absolute;
	    -webkit-transition: padding $hov-speed, box-shadow $hov-speed;
	    transition: padding $hov-speed, box-shadow $hov-speed;
	    -webkit-transform-origin: 100% 100%;
	    -ms-transform-origin: 100% 100%;
	    transform-origin: bottom right;
	}
	
	.tab-right::before {
	    position: relative;
	    display: block;
	    left: 0px;
	    height: .5vw;
	    //margin-bottom: -1px;
	    -webkit-transform: skewX(45deg);
	    -ms-transform: skewX(45deg);
	    transform: skewX(45deg);
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        top: -1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-right::after {
	    position: relative;
	    display: block;
	    left: -.5vw;
	    height: .5vw;
	    //margin-top: -1px;
	    -webkit-transform: skewX(-45deg);
	    -ms-transform: skewX(-45deg);
	    transform: skewX(-45deg);
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        bottom: -1.5vw;
	        left: -1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-left::before {
	    top: -.5vw;
	    left: 0;
	    height: .52vw;
	    -webkit-transform: skewX(-45deg);
	    -ms-transform: skewX(-45deg);
	    transform: skewX(-45deg);
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        top: -1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-left::after {
	    bottom: -.5vw;
	    left: .5vw;
	    height: .5vw;
	    -webkit-transform: skewX(45deg);
	    -ms-transform: skewX(45deg);
	    transform: skewX(45deg);
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        bottom: -1.5vw;
	        left: 1.5vw;
	        height: 1.52vw;
	    }
	}
	
	.tab-down::before,
	a.button::before {
	    bottom: -.5vw;
	    left: .5vw;
	    height: .5vw;
	    -webkit-transform: skewX(45deg);
	    -ms-transform: skewX(45deg);
	    transform: skewX(45deg);
	    width: 50%;
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        bottom: -1.5vw;
	        left: 1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-down::after,
	a.button::after {
	    bottom: -.5vw;
	    right: .5vw;
	    height: .5vw;
	    -webkit-transform: skewX(-45deg);
	    -ms-transform: skewX(-45deg);
	    transform: skewX(-45deg);
	    width: 50%;
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        bottom: -1.5vw;
	        right: 1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-up::before,
	a.button>div::before {
	    top: -.5vw;
	    left: 0;
	    height: .52vw;
	    -webkit-transform: skewX(-45deg);
	    -ms-transform: skewX(-45deg);
	    transform: skewX(-45deg);
	    width: 50%;
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        top: -1.5vw;
	        height: 1.5vw;
	    }
	}
	
	.tab-up::after,
	a.button>div::after {
	    top: -.5vw;
	    right: 0;
	    height: .52vw;
	    -webkit-transform: skewX(45deg);
	    -ms-transform: skewX(45deg);
	    transform: skewX(45deg);
	    width: 50%;
	    @media only screen and (max-width: $max-size-mobile) and (orientation: portrait) {
	        top: -1.5vw;
	        height: 1.5vw;
	    }
	}