.weapons .overlay{
	background-image: url(/assets/images/bg-weapons-overlay.jpg);
	.overlay-nav{
		div.item{
			width:10vw;
		}
	}
	.content{
		.content-bucket{
			width:300vw;
			.content-block{
				div.copy{
					margin-left:9vw;
					width:40vw;
					@media only screen and (max-width: $max-size-mobile) and (orientation:portrait)
					{
						width:100%;
					}
					h1{
						font-size:2.5em;
						margin-top:15vh;
					}
					h3{
						font-size:4.4em;
						font-weight:900;
						line-height:1;
					}
					font{
						margin: 2vh 0 5vh 0;
						display:block;
						width:25vw;
						color:#fff;
						font-size: 1em;
						line-height:1.4;
					}
				}
				.stats{
					position:absolute;
					left:9vw;
					bottom:calc(10vh);
					width:50vh;
					max-width:50vw;
					img{
						width:100%;
					}
				}
				.details{
					width:60vw;
					position:absolute;
					top:25vh;
					right:5vw;
					width:120vh;
					max-width:70vw;
					img{
						width:100%;
					}
				}
			}
			.content-block#weapons-grenade{
				.stats{
					bottom:0vw;
					left:20vw;
				}
			}
		}
	}
	@media only screen and (max-width: $max-size-mobile) and (orientation:portrait)
	{
		.content {
			.content-bucket {
				.content-block {
					div.copy {
						margin-left:15vw;
						h1{
							font-size:4vw;
							margin-top:3vh;
						}
						h3{
							font-size:7vw;
						}
						font{
							width:70vw;
							font-size:1.2em;
						}
						a.button{
							margin-left:-10vw;
							width:90vw;
							text-align:center;
						}
					}
					.mobile-details{
						width:70vw;
						height:50vw;
						text-align:center;
						margin-top:15vh;
						img{
							max-width:100%;
							max-height:100%;
						}
					}
					.stats{
						width:80vw;
						max-width:80vw;
					}
					
				}
				.content-block#weapons-grenade{
					.stats{
						left:10vw;
					}
				}
			}
		}
	}
}